<template>
  <div class="Products">


  </div>
</template>

<style lang="scss">
.Products{
  height: 500vh;
}
</style>

<script>
  export default {
    name: 'Products',
    data(){
      return {

      }
    },
    mounted(){

    },
    methods:{

    },
    computed:{
      
    }
  }
</script>


